"use client";

import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useState } from "react";
import GSIconLocationWhite from "../../../../public/images/new/GS-Icon-Location-White.svg";
import GSTexture from "../../../../public/images/new/GS-Texture.png";
interface Props {
  caption: string;
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  categories: {
    id: string;
    categoryName: string;
    communityDetails: {
      image: Image;
      caption: string | null | undefined;
      location: string | null | undefined;
    }[];
    bigText: string;
    smallText: string;
  }[];
}
function SectionTabs(props: Props) {
  const [activeTab, setActiveTab] = useState(props?.categories?.[0]?.id);
  const pathName = usePathname();
  const selectedCategory = props?.categories?.find(category => category?.id === activeTab)!;
  return <div className="section__tabs" data-sentry-component="SectionTabs" data-sentry-source-file="SectionTabs.tsx">
      <div className="section__tabs-container section__container--small">
        <div className="section__tabs-header">
          <div className="section__tabs-header-text">
            <p>
              <span>{props?.caption}</span>
            </p>
            <h2 className="heading__secondary">{props?.title}</h2>
            <p>{props?.description}</p>
          </div>
        </div>
        <div className="section__tabs-group">
          {props?.categories?.map((category, index) => <button key={index} onClick={() => {
          setActiveTab(category?.id);
          if (typeof window !== "undefined" && typeof window.gtag === "function") {
            window.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
              event_label: "Click event on Community",
              page_path: `${process.env.NEXT_PUBLIC_APP_URL}${pathName}`
            });
          }
        }} className={category?.id === activeTab ? "active" : ""}>
              {category.categoryName}
            </button>)}
        </div>
        <div className="section__tabs-grid">
          <SectionTabsItem index={0} {...selectedCategory?.communityDetails?.[0]} data-sentry-element="SectionTabsItem" data-sentry-source-file="SectionTabs.tsx" />
          <SectionTabsItem index={1} {...selectedCategory?.communityDetails?.[1]} data-sentry-element="SectionTabsItem" data-sentry-source-file="SectionTabs.tsx" />
          <SectionTabsItem index={2} {...selectedCategory?.communityDetails?.[2]} data-sentry-element="SectionTabsItem" data-sentry-source-file="SectionTabs.tsx" />
          <div className="section__tabs-grid-item section__tabs-grid-item-special">
            <div className="section__tabs-grid-item-special-texture">
              <Image src={GSTexture} alt="Texture" width={300} height={300} loading="lazy" data-sentry-element="Image" data-sentry-source-file="SectionTabs.tsx" />
            </div>
            <div className="section__tabs-grid-item-special-text">
              <p className="colored-text">{selectedCategory?.bigText}</p>
              <span>{selectedCategory?.smallText}</span>
            </div>
          </div>
          <SectionTabsItem index={3} {...selectedCategory?.communityDetails?.[3]} data-sentry-element="SectionTabsItem" data-sentry-source-file="SectionTabs.tsx" />
          <SectionTabsItem index={4} {...selectedCategory?.communityDetails?.[4]} data-sentry-element="SectionTabsItem" data-sentry-source-file="SectionTabs.tsx" />
        </div>
      </div>
    </div>;
}
export default SectionTabs;
function SectionTabsItem(props: Props["categories"][number]["communityDetails"][number] & {
  index: number;
}) {
  const MotionImage = motion(Image);
  return <div className="section__tabs-grid-item" data-sentry-component="SectionTabsItem" data-sentry-source-file="SectionTabs.tsx">
      <div className="section__tabs-grid-item-img">
        <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="SectionTabs.tsx">
          <MotionImage initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0
        }} transition={{
          duration: 1,
          delay: 0.1 * props.index
        }} src={props?.image?.src} alt={props?.image?.alt ?? "image gallery"} width={300} height={300} data-sentry-element="MotionImage" data-sentry-source-file="SectionTabs.tsx" />
        </AnimatePresence>
      </div>

      <Image src={GSTexture} alt="Texture" width={300} height={300} className="section__tabs-grid-item-texture" loading="lazy" data-sentry-element="Image" data-sentry-source-file="SectionTabs.tsx" />

      <div className="section__tabs-grid-item-text">
        <h4>{props?.caption}</h4>
        {props?.location && <div className="section__tabs-grid-item-text-location">
            <div className="section__tabs-grid-item-text-icon">
              <Image src={GSIconLocationWhite} alt="Location Icon" width={20} height={20} loading="lazy" />
            </div>
            {props?.location}
          </div>}
      </div>
    </div>;
}